import React from "react";
import Sidebar from "../components/Sidebar";
import { CgProfile } from "react-icons/cg";
import { BsArrow90DegRight, BsCurrencyDollar } from "react-icons/bs";
import { FaRegUser, FaUser, FaUsers } from "react-icons/fa6";
import { RiNftLine, RiPagesLine } from "react-icons/ri";
import {
  MdDomain,
  MdOutlinePriceChange,
  MdSpaceDashboard,
} from "react-icons/md";
import {withProtectedRoute} from "../withProtectedRoute";
import { useActiveAccount } from "thirdweb/react";
import { FaTools } from "react-icons/fa";
import { BiTransfer, BiUser } from "react-icons/bi";
import { SlTarget } from "react-icons/sl";
import { BsStack } from "react-icons/bs";
import {
  IoCreateOutline,
  IoCreateSharp,
  IoFileTrayStackedOutline,
} from "react-icons/io5";
import { PiUsers } from "react-icons/pi";

const DashboardLayout = ({ children }) => {

  const routes = [
    {
      icon: MdSpaceDashboard,
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      icon: MdDomain,
      name: "My Domains",
      children: [
        {
          name: "Top-Level Domains",
          icon: IoCreateOutline,
          path: "/dashboard/domain/tlds",
        },
        {
          name: "Sub-Level Domains",
          icon: IoCreateOutline,
          path: "/dashboard/domain/slds",
        },
      ],
    },
    // {
    //   icon: RiPagesLine,
    //   name: "Manage Pages",
    //   path: "/dashboard/manage-pages",
    // },
    {
      icon: RiNftLine,
      name: "NFT Launchpad",
      // path: "/dashboard/nft-launchpad",
      // comingSoon: true,
      children: [
        {
          name: "Create NFT",
          icon: IoCreateOutline,
          path: "/dashboard/nft-launchpad",
        },
        {
          name: "AirDrop",
          icon: SlTarget,
          path: "#",
          comingSoon: true,
        },
        {
          name: "Create Collection",
          icon: IoFileTrayStackedOutline,
          path: "#",
          comingSoon: true,
        },
      ],
    },
    {
      icon: PiUsers,
      name: "Audience Management",
      path: "/dashboard/audience-management",
      comingSoon: true,
    },
    {
      icon: FaTools,
      name: "Tools & Settings",
      children: [
        {
          name: "Domain Transfer",
          path: "/dashboard/transfer",
          icon: BsArrow90DegRight,
        },
        {
          name: "Sld Pricing",
          path: "/dashboard/sld-management",
          icon: MdOutlinePriceChange,
          comingSoon: true,
        },
        {
          name: "Reverse Resolution",
          path: "/dashboard/resolution",
          icon: BiTransfer,
          comingSoon: true,
        },
        {
          name: "Account Settings",
          path: "/account-settings",
          icon: FaRegUser,
          comingSoon: true,
        },
      ],
    },
    {
      icon: BsCurrencyDollar,
      name: "Payouts",
      path: "/dashboard/financials",
    },
  ];


  return (
    <div className=" relative flex flex-col md:flex-row gap-2 mt-32 ">
      <Sidebar routes={routes} />
      <div className="flex-grow h-full dark:bg-jacarta-900">
        <main className="px-4 md:px-8">{children}</main>
      </div>

    </div>
  );
};

export default withProtectedRoute(DashboardLayout);
