import Eco from "./Eco";
import Fade from 'react-reveal/Fade';

const EcoSystem = ({ noNegative, onlyWallets }) => {
    return (
        <Fade ssrReveal>
            <div className={`overflow-hidden  container dark:bg-transparent relativemb-[20px] md:mb-[50px] lg:mb-[40px] `}>


                <div className="hidden dark:block absolute right-0 top-0 bottom-0 left-auto z-[10]
"
                    style={{
                        backgroundImage: 'linear-gradient(270deg, #000000, rgba(7, 9, 27, 0))',
                    }}
                ></div>
                <div className="hidden dark:block absolute right-auto top-0 bottom-0 left-0 z-[10]" style={{
                    backgroundImage: 'linear-gradient(90deg, #000000, rgba(7, 9, 27, 0))',
                }}></div>
                {/* <div className=" animate-marquee space-x-8 flex relative"> */}

                <Eco onlyWallets={onlyWallets} />
                {/* </div> */}


            </div>
        </Fade>
    );
};
export default EcoSystem;


// bg-gradient-to-tr from-[#000000] via-transparent to-[#00000000"]
