import React, { useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import Logo from "./../../public/Logo-dark.png";
import WhiteLogo from "./../../public/Logo-white.png";

import { SiHiveBlockchain, SiPolygon } from "react-icons/si";

import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import {
  isChildrenPageActive,
  isPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FaListUl, FaUser, FaDollarSign } from "react-icons/fa";
import { FaAngleDown, FaCartShopping, FaArrowRight } from "react-icons/fa6";
import { cartDrawerToggle } from "../../redux/cartSlice";
import {
  ConnectWallet,
  useActiveAccount,
  useBalance,
  // useEmbeddedWalletUserEmail,
  useDisconnect,
  useIsAutoConnecting,
  // useConnectionStatus,
} from "thirdweb/react";
import { polygon } from "thirdweb/chains";

import { useTheme } from "next-themes";
import { IoWalletOutline } from "react-icons/io5";

import MainDrawer from "../common/MainDrawer";
import WalletDropdown from "../common/WalletMenu";
import { useWalletDisconnect } from "../../hooks/useWalletDisconnect";
import { useWalletBalanceV5 } from "../../hooks/useWalletBalanceV5";

export default function Header04() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [isScroll, setScroll] = useState(false);

  const user = useActiveAccount();
  const { theme } = useTheme();
  // const { data: userEmail, isFetching } = useEmbeddedWalletUserEmail();
  // const { data: userBalance, isLoading: userBalanceFetching } = useBalance();
  const { 
    displayValue:userBalance, 
    symbol,
    getFormattedBalance, 
    isLoading:userBalanceFetching, 
  } = useWalletBalanceV5(polygon);
  const { handleDisconnect } = useWalletDisconnect();
  // const connectionStatus = useConnectionStatus();

  // console.log(connectionStatus)


  // sticky menu
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 4) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
  }, []);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => setIsOpen(!isOpen);
  const home = [

    {
      id: uuidv4(),
      name: "Domains",
      path: "/domains",
    },
    {
      id: uuidv4(),
      name: "Launch",
      path: "/community",
      pages: [
        {
          id: uuidv4(),
          name: "NFT",
          path: "/community",
        },
        {
          id: uuidv4(),
          name: "Drop",
          path: "/community",
        },
        {
          id: uuidv4(),
          name: "Collection",
          path: "/community",
        },
      ],
    },
    {
      id: uuidv4(),
      name: "Pricing",
      path: "/pricing",
      // comingSoon: true,
    },
    {
      id: uuidv4(),
      name: (
        <span className="flex items-center gap-2 text-accent">
          Start for Free
          <FaArrowRight className="h-3 w-3 " />
        </span>
      ),
      comingSoon: true,
      path: "#",
    },
  ];

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };
  const dispatch = useDispatch();


  const { cartItems } = useSelector((state) => state.cart);


  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);



  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isAutoConnecting = useIsAutoConnecting();
  const [isAuthReady, setIsAuthReady] = useState(false);

  // Wait for initial auth check to complete
  useEffect(() => {
    if (!isAutoConnecting) {
      setIsAuthReady(true);
    }
  }, [isAutoConnecting]);

  // Loading placeholder component
  const AuthPlaceholder = () => (
    <div className="flex items-center justify-center px-2 py-2 rounded-md min-w-28 border dark:border-jacarta-600 border-jacarta-300 bg-jacarta-100 dark:bg-jacarta-700">
      <div className="animate-pulse h-5 w-20"></div>
    </div>
  );

  // Function to render the auth button with proper loading states
  const renderAuthButton = () => {
    // Show placeholder during initial load
    if (!isAuthReady || isAutoConnecting) {
      return <AuthPlaceholder />;
    }

    // If no user, show login button
    if (!user) {
      return (
        <Link
          href="/signin"
          className="flex items-center justify-center px-2 py-2 rounded-md min-w-28 cursor-pointer border dark:border-none border-jacarta-300 dark:hover:bg-jacarta-600 hover:bg-jacarta-900 hover:text-white gap-2 group transition-colors hover:border-transparent dark:bg-gradient-to-tr from-hotPink via-purplish to-accent"
        >
          <IoWalletOutline className="transition-colors group-hover:text-white text-jacarta-900 dark:text-white h-5 w-5" />
          <span className="text-left text-sm font-semibold">
            Login
          </span>
        </Link>
      );
    }

    // If user is connected, show wallet dropdown
    return (
      <WalletDropdown
        user={user?.address}
        userEmail={"userEmail"}
        userBalance={getFormattedBalance(3)}
        disconnectWallet={handleDisconnect}
        isFetching={false}
        userBalanceFetching={userBalanceFetching}
      />
    );
  };

  // Function to render additional buttons (cart, profile)
  const renderAdditionalButtons = () => {
    // if (!isAuthReady || !user) return null;

    return (
      <>
        <div
          onClick={toggleDrawer}
          className="border-jacarta-300 dark:hover:bg-accent dark:bg-jacarta-900 hover:bg-jacarta-900 group flex h-10 w-10 items-center justify-center rounded-full border transition-colors focus:border-transparent cursor-pointer"
          aria-label="profile"
        >
          <FaListUl className="group-hover:text-white text-jacarta-900 dark:text-white" />
        </div>
        <div
          onClick={() => dispatch(cartDrawerToggle())}
          className="border-jacarta-300 dark:hover:bg-jacarta-600 hover:bg-jacarta-900 hover:text-white flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:bg-jacarta-900 cursor-pointer relative"
          aria-label="Cart"
        >
          <FaCartShopping />
          {cartItems.length > 0 && (
            <div className="bg-accent w-4 h-4 text-sm absolute top-0 right-0 rounded-full text-center text-jacarta-900">
              {cartItems.length}
            </div>
          )}
        </div>
      </>
    );
  };

  const NavLink = ({ page }) => (
    <div className="relative group">
      {page.pages ? (
        <button
          className="dropdown-toggle text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-center py-3.5 text-base dark:text-white lg:px-5 w-full"
        >
          <span>{page.name}</span>
          <FaAngleDown className="ml-1" />
        </button>
      ) : (
        <Link
          href={page.path}
          className={`dropdown-toggle text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-center py-3.5 text-base dark:text-white lg:px-5 w-full ${
            page.comingSoon ? 'cursor-default' : ''
          }`}
          onClick={(e) => page.comingSoon && e.preventDefault()}
        >
          <span
            className={
              isPageActive(home, route.asPath)
                ? "text-accent dark:text-accent"
                : ""
            }
          >
            {page.name}
          </span>
        </Link>
      )}
      
      {page.pages && (
        <div className="dropdown-menu dark:bg-jacarta-800 group-hover:visible group-hover:opacity-100 absolute left-0 top-10 z-10 hidden min-w-[150px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform lg:invisible lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:translate-y-2">
          {page.pages.map((subpage) => (
            <Link
              key={subpage.id}
              href={subpage.path}
              className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-200  flex items-center rounded-xl px-5 py-2 transition-colors"
            >
              <span className="font-display text-jacarta-900 text-sm dark:text-white">
                {subpage.name}
              </span>
            </Link>
          ))}
        </div>
      )}
      
      {page.comingSoon && (
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity duration-200 
                    bg-accent shadow-md
                    text-jacarta-900 font-medium text-[12px] 
                    py-1 px-4 rounded-md whitespace-nowrap
                    left-1/2 top-[90%] transform -translate-x-1/2 z-50">
          Coming Soon
        </div>
      )}
    </div>
  );

  return (
    <>
      {/* main desktop menu sart*/}
      <header
        className={`js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors ${
          isScroll ? "js-page-header--is-sticky" : ""
        }`}
      >
        {!isScroll && (
          <div className="bg-gradient-to-r from-hotPink via-purplish to-accent  text-center p-2">
            <p className="text-[13px] md:text-lg font-semibold text-white uppercase flex items-center justify-center gap-2">
              <SiHiveBlockchain className="fill-accent" /> ALPHA LAUNCH NEW FEATURES COMING SOON 🚀
            </p>
          </div>
        )}
        <div className="flex items-center px-6 py-6 xl:px-24 ">
          <Link className="shrink-0 " href="/">
            <div className="dark:hidden">
              <Image
                src={WhiteLogo}
                height={28}
                width={90}
                alt="Netzylo"
                className=" h-auto "
              />
            </div>
            <div className="hidden dark:block">
              <Image src={Logo} height={28} width={90} alt="Netzylo" />
            </div>
          </Link>

          {/* <Link className="md:hidden shrink-0" href="/">
            <div className="dark:hidden">
              <Image
                src='/mbl.svg'
                height={28}
                width={28}
                alt="Netzylo"
                className=" h-auto "
              />
            </div>
            <div className="hidden dark:block">
              <Image src='/mbl-dark.svg' height={28} width={28} alt="Netzylo" />
            </div>
          </Link> */}
          {/* End  logo */}

          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent w-full">
            <nav className="navbar mx-auto mt-24 lg:mt-0 ">
              <ul className="flex flex-col lg:flex-row lg:items-center lg:justify-center translate-x-20 ">
                {home.map((page) => (
                  <li key={page.id} className="js-nav-dropdown group relative">
                    <NavLink page={page} />
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          {/* header menu conent end for desktop */}

          <div className="hidden  items-center justify-end lg:flex space-x-2">
         
         
            {renderAdditionalButtons()}
            {renderAuthButton()}
          </div>
          {/* mobile view  */}
          <div className="ml-auto flex justify-center space-x-2 lg:hidden">
         
         
            {renderAdditionalButtons()}
            {renderAuthButton()}

          </div>
          {/* End mobile toggle button */}
        </div>
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <Image
              src={Logo}
              height={28}
              width={130}

              alt="Netzylo"
              className=" h-auto "
            />
          </div>

          <div className="hidden dark:block">
            <Image src={WhiteLogo} height={28} width={130} alt="Netzylo" />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent  dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-900 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}


        <form action="search" className="relative mt-24 mb-8 w-full lg:hidden">
          <input
            type="search"
            className="text-jacarta-900 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
            placeholder="Search"
          />
          <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-500 h-4 w-4 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </span>
        </form>
        {/* End search form mobile menu  */}

        <nav className="navbar w-full">
          <ul className="flex flex-col lg:flex-row">
            <li className="js-nav-dropdown group relative">
              <button
                onClick={() => mobileCollapse(home.id)}
                className="dropdown-toggle text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              >
                <span
                  className={
                    isParentPageActive(home.pages, route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }
                >
                  {home.name}
                </span>
                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === home.id ? "block" : "hidden"
                }`}
              >
                {home?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link
                      href={page.path}
                      className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                    >
                      <span
                        className={`font-display ${
                          isChildrenPageActive(route.asPath, page.path)
                            ? "text-accent dark:text-accent"
                            : "text-jacarta-900"
                        } text-sm dark:text-white`}
                      >
                        {page.name}
                      </span>
                      {page.condition ? (
                        <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                          new
                        </span>
                      ) : undefined}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/create">
                <button className="text-jacarta-900 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/create", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }
                  >
                    Create
                  </span>
                </button>
              </Link>
            </li>
          </ul>
        </nav>
        {/* End navbar mobile menu  */}
      </div>


      <MainDrawer
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  toogle={toggleDrawer}
                />

    </>
  );
}
