import React from "react";
import Image from "next/image";
import { useTheme } from "next-themes";
import Fade from "react-reveal/Fade";
import Link from "next/link";

const HowItWorks = ({ cryptopage }) => {
  const { theme } = useTheme();

  return (
    <section>
      <div className="container relative pt-[68px] md:pt-[98px]  lg:pt-[118px] lg:pb-20 ">
        {/* <div className="mt-20 max-w-[700px] lg:max-w-full mx-auto  rounded-[12px] bg-white dark:bg-jacarta-900 gradient-box relative  "> */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-14 relative  ">
          <div className="max-w-[450px]  flex flex-col justify-center items-start">
            <Fade ssrReveal bottom>
              <h6 className="text-jacarta-900 font-light font-display mb-4 text-left text-[30px] md:text-[44px] dark:text-white  lg:text-[48px] xl:text-[60px] leading-tight	">
                Built for brands of any size{" "}
              </h6>
            </Fade>
            <Fade ssrReveal left delay={350}>
              <p className="text-jacarta-900 dark:text-white mb-[28px] text-left text-[16px] md:text-[18px] font-normal ">
                Whether you're a global brand or an individual, we provide a
                full solution for domain management, NFT distribution, and
                community engagement.
              </p>
            </Fade>
            <Fade ssrReveal bottom delay={350}>
              <div className="flex items-center justify-between gap-8">
                <Link
                  href="/signup"
                  className="min-w-[195px] items-center flex justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent cursor-pointer  py-[22px] px-[32px] md:py-[24px] md:px-[38px] transition-all duration-500 hover:-translate-y-1 relative z-[100] text-white  "
                >
                  Start now
                </Link>
                <Link
                  href="/community"
                  className="min-w-[195px] items-center hidden lg:flex justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800  hover:bg-jacarta-base cursor-pointer  py-[22px] px-[32px] md:py-[24px] md:px-[38px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white dark:hover:bg-white dark:hover:text-gray-300"
                >
                  Learn more
                </Link>
              </div>
            </Fade>
          </div>
       
          <div>
            <div className=" mx-auto">
              <div className="relative w-full">
                <div className="">
                  <Fade ssrReveal right delay={350}>
                    {cryptopage ? (
                      <Image
                        width={1920}
                        height={1080}
                    
                         src="/images/integration-2.png"
                        alt="wallet"
                        className=""
                      />
                    ) : (
                      <Image
                        width={1920}
                        height={1080}
                        src="/images/community-image.svg"
                        alt="wallet"
                        className="scale-110 mt-8"
                      />
                    )}
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default HowItWorks;
